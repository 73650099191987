import { createSlice } from '@reduxjs/toolkit'

export const loginSlice = createSlice({
  name: 'user',
  initialState: {
      authenticated: 'pending',
      bearer: "ghnkDUtbtivVZqLEvKw0yGPshZf+dY2gwFtER9qktvWuLIdQtY9as8S1lV+q6Rs3gDkVYQEI4+odQ23/ojBdEpyf2Huc/a0dzh6HhQcYHCuPe5e8/YlPIYejXmrTOjGkILz24ZJTitUvA8eUzQXgyB1Vj3dmHyUC/hm0kohR+FVetRtMKaV3fVoTTH3UmD5x/9DK8HVcTQiepxJ7uqPB2b8Cg33VSrryJKZFAYLroFtS37ysjhuOpSGc90piQuHwwh7sHNhtY05lc4vHzuZYqa1fkUn5PmRHo7acBwqyFfVFgTvLkjwca3VcBH14op8zkZtR9ZrfHmM4nwfn/87tQg=="
  },
  reducers: {
    setAuthentication: (state, auth) => {
        state.authenticated = auth.payload
    },
    setUser: (state, user) => {
        // const newState = {
        //     authenticated: true, 
        //     ...user.payload
        // }

          return user.payload
    },
    clearUser: () => {
      console.log('here')
      console.log(Object.keys(sessionStorage))
      for (let i = 0; i < sessionStorage.length; i++) {
        console.log(sessionStorage.key(i))
        sessionStorage.removeItem(sessionStorage.key(i))
      }
      // sessionStorage.removeItem('persist:root')
      //   return {
      //     authenticated: 'pending',
      //     bearer: "TkQ6kYJB3Prcgq8WBJjv2XJijJM9SNAONNYPMGVgOkHZGbCc1D+rxh8Pa7NMee/1q0zKqZy6WJAjqxuYBMwBxwo13gFiSDG0ZoLWanfB5nLbm/TOlO3QC5HYrrF5A1AQ7/o83zaCKuBA7nSY1UMJjAqHqK7a74YGb71V0PQJT6l4hcEklPJWpD9o0+4KNasdSK7wwM9gtePQWze5prR+Ff1VAM9WpaSlfi+6oYZwE/ZAApt/WJKDW2UWTcuCXNDiLqswAgwz4JSZhMd5KNCSJCPCfNJh7FucVZoD2Xv1FxxJ0h6oPrIH/2BXEoDN3RKLuAMOQhke5CFATxmrjHszxQ=="
      // }
    }
  }
})

// Action creators are generated for each case reducer function
export const { setAuthentication, setUser, clearUser } = loginSlice.actions

export default loginSlice.reducer