// import { useEffect, useState, useContext } from "react"
// import { useSelector, useDispatch } from 'react-redux';
// import { addData } from "../../utils/redux/dataSlice";
import ClipLoader from "react-spinners/ClipLoader";
// import { isEqual } from "lodash";

import { DataCards } from "../visualizations/Cards";
import { UnpaidARChart, ResponsibleWIPPie } from '../visualizations/charts/PieCharts';
// import { StaticWIPContext } from "../../pages/reports/wip/WIPreports";

import "../../style/static-ar-card.css";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../../utils/indexeddb/db";

// export function StaticWIP(props) {

//     const WIP = useLiveQuery(async () => {
//         const WIPs = await db.STATIC_WIP
//             .where('FOCUS')
//             .equals(props.option)
//             .toArray()
        
//         return WIPs[0]
//     })
    
//     console.log(WIP)
    
//     try {
//         // if (AR) {
//             return (
//                 <section className={`${props.stylesheet ? props.stylesheet : "static"}-metric-panel `}>
//                     <h5>{props.header}</h5>
//                     <div id="data-area">
//                         {/* <h5>{props.header}</h5> */}
//                         <div id="dollar-metrics">
//                             <h6>Outstanding WIP</h6>
//                             {/* <h3 className="lrg-dollar-amount">{"$"+arData[maintype]}</h3> */}
//                             <h3 className="lrg-dollar-amount">{"$" + WIP.OUTSTANDING_WIP.toFixed(2).toString().replace(/\d(?=(\d{3})+\.)/g, '$&,')}</h3>
//                         </div>
//                         <div id="static-ar-chart">
//                             <UnpaidARChart chartData={WIP} centerLabel={props.header} />
//                         </div>
//                         <div id="static-ar-cards">
//                             {/* <Cards level={props.objKey} metric='UNPAID_INVOICE' /> */}
//                             <Cards level={props.option} metric='PERCENT_CURR' color='#5C6FC5' cardValue={WIP.PERCENT_CURR} />
//                             <Cards level={props.option} metric='PERCENT_31_60' color='#95CB77' cardValue={WIP.PERCENT_31_60} />
//                             <Cards level={props.option} metric='PERCENT_61_90' color='#F4CA5E' cardValue={WIP.PERCENT_61_90} />
//                             <Cards level={props.option} metric='PERCENT_OVERDUE' color='#FF7070' cardValue={WIP.PERCENT_OVERDUE} />
//                         </div>
//                     </div>
//                 </section>
//             )
//         // } else {
//         //     return (
//         //         <ClipLoader loading={AR} />
//         //     )
//         // }
//     } catch (error) {
//         console.error(error);
//         return (
//             <ClipLoader loading={WIP} />
//         )
//     }
// }

// export function StaticValues(dataTable, type) {
//     console.log(dataTable, type)
//     const ComputPercentages = (values) => {
//         let shape = {
//             outstanding: values.outstanding,
//             PERCENT_31_60: (values["31-60 Days"] / numberOfEntries) * 100,
//             PERCENT_61_90: (values["61-90 Days"] / numberOfEntries) * 100,
//             PERCENT_OVERDUE: (values["Overdue WIP"] / numberOfEntries) * 100,
//             PERCENT_CURR: (values["Current"] / numberOfEntries) * 100,
//         }
//         return shape
//     }

//     let outstanding;
//     let numberOfEntries;
//     if (dataTable !== null) {
//         numberOfEntries = dataTable.length;
//         outstanding = dataTable.reduce((acc, nxt) => {
//             acc.outstanding += nxt[type];
//             acc[nxt.AGING_PERIOD] += 1
//             return acc;
//         }, { outstanding: 0, "31-60 Days": 0, "61-90 Days": 0, "Overdue WIP": 0, "Current": 0 })
//         return ComputPercentages(outstanding);
//     }
//     console.log('outstanding is :', outstanding)
//     return outstanding;
// }

// function labeler(type) {
//     let label = "AR";
//     let maintype = "UNPAID_INVOICE";
//     switch (type.objKey.slice(0, 3)) {
//         case "arDeptStatic":
//             label = "AR";
//             break;
//         case "arRespStatic":
//             label = "AR";
//             break;
//         case "wip":
//             label = "WIP";
//             break;
//         default:
//             label = "AR";
//     }
// }

// export function StaticMetricView({ data, header, label, stylesheet }) {
//     // const { staticWIP, setStaticWIP, staticValue } = useContext(StaticWIPContext);
//     console.log("staticmetricveiw", data);
//     const outstanding = data ? parseFloat(data.outstanding) : 0;
//     const current = data ? data.PERCENT_CURR : 0;
//     const overdue = data ? data.PERCENT_OVERDUE : 0;
//     const thirty = data ? data.PERCENT_31_60 : 0;
//     const sixty = data ? data.PERCENT_61_90 : 0;

//     return (
//         <section className={`${stylesheet ? stylesheet : "static"}-metric-panel `}>
//             {/* <h5>{header}</h5> */}
//             <div id="data-area">
//                 <div id="dollar-metrics">
//                     <h6>Outstanding{` ${label}`}</h6>
//                     {/* <h3 className="lrg-dollar-amount">{"$"+arData[maintype]}</h3> */}
//                     <h3 className="lrg-dollar-amount">{"$" + outstanding.toFixed(2).toString().replace(/\d(?=(\d{3})+\.)/g, '$&,')}</h3>
//                 </div>
//                 <div id="static-ar-chart">
//                     <UnpaidARChart chartData={data} centerLabel={header} />
//                 </div>
//                 <div id="static-ar-cards">
//                     {/* <Cards level={objKey} metric='UNPAID_INVOICE' /> */}
//                     <Cards cardValue={current} metric='PERCENT_CURR' color='#5C6FC5' />
//                     <Cards cardValue={thirty} metric='PERCENT_31_60' color='#95CB77' />
//                     <Cards cardValue={sixty} metric='PERCENT_61_90' color='#F4CA5E' />
//                     <Cards cardValue={overdue} metric='PERCENT_OVERDUE' color='#E66B68' />
//                 </div>
//             </div>
//         </section>
//     )
// }

export function DoubleWIP({ left_data, right_data, left_header, right_header, label, data }) {
    const outstanding = data.reduce((total, obj) => {
        return total + obj.WIPOUTSTANDING
    }, 0)

    const current = data.reduce((total, obj) => {
        return total + obj.OUTSTANDING_CURRENT
    }, 0)
    
    const overdue = data.reduce((total, obj) => {
        return total + obj.OUTSTANDING_OVERDUE
    }, 0)
    
    const thirty = data.reduce((total, obj) => {
        return total + obj.OUTSTANDING_31_60
    }, 0)
    
    const sixty = data.reduce((total, obj) => {
        return total + obj.OUTSTANDING_61_90
    }, 0)
    

    return (
        <section className={`static-metric-panel`}>
            {/* <h5>{header}</h5> */}
            <div id="data-area">
                <div id="dollar-metrics">
                    <h6>{`${label} WIP`}</h6>
                    {/* <h3 className="lrg-dollar-amount">{"$"+arData[maintype]}</h3> */}
                    {/* <h3 className="lrg-dollar-amount">${outstanding}</h3> */}
                    <h3 className="lrg-dollar-amount">{"$" + outstanding.toFixed(2).toString().replace(/\d(?=(\d{3})+\.)/g, '$&,')}</h3>
                </div>
                <div className="double-ring-section">
                    <ResponsibleWIPPie chartData={left_data} title={left_header} />
                    <ResponsibleWIPPie chartData={right_data} title={right_header}/>
                </div>
                <div id="static-ar-cards">
                    {/* <Cards level={objKey} metric='UNPAID_INVOICE' /> */}
                    <DataCards value={"$" + current.toFixed(2).toString().replace(/\d(?=(\d{3})+\.)/g, '$&,')} title='Current' color='#5C6FC5' />
                    <DataCards value={"$" + thirty.toFixed(2).toString().replace(/\d(?=(\d{3})+\.)/g, '$&,')} title='31-60 Days' color='#95CB77' />
                    <DataCards value={"$" + sixty.toFixed(2).toString().replace(/\d(?=(\d{3})+\.)/g, '$&,')} title='61-90 Days' color='#F4CA5E' />
                    <DataCards value={"$" + overdue.toFixed(2).toString().replace(/\d(?=(\d{3})+\.)/g, '$&,')} title='Overdue WIP' color='#FF7070' />
                </div>
            </div>
        </section>
    )
}