import Dexie from 'dexie'
import { localTables } from '../DexieTables'
import { store } from '../redux/store'
import { forceRefreshAllTables } from '../redux/tableLoadingSlice'

// Create database instance as singleton
export const db = new Dexie("MagnifyDB")

// Configure database with tables using integer version
db.version(5).stores(localTables)

// Open database with improved error handling
db.open()
    .catch(Dexie.VersionError, async (error) => {
        console.warn("Version error, attempting recovery:", error)
        try {
            // Close any existing connections
            db.close()
            // Delete and recreate database
            await db.delete()
            // Reopen with new schema
            await db.open()
            // Force refresh all tables on version error
            store.dispatch(forceRefreshAllTables())
            return
        } catch (err) {
            console.error("Recovery failed:", err)
            throw err
        }
    })
    .catch((err) => {
        console.error("Database initialization error:", err.stack || err)
    })

// Handle version changes gracefully
db.on("versionchange", async (event) => {
    console.warn("Database version change detected")
    
    try {
        // Close current connection
        await db.close()
        
        // Give other tabs time to close their connections
        await new Promise(resolve => setTimeout(resolve, 100))
        
        // Force refresh all tables on version change
        store.dispatch(forceRefreshAllTables())
        
        // Reload the page to get the new version
        window.location.reload()
    } catch (error) {
        console.error("Error during version change:", error)
        window.location.reload()
    }
})