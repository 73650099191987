import { useEffect, useState, useMemo, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setTableStatus, refreshStaleData, refreshTable } from '../redux/tableLoadingSlice';
import { batchedBulkPut } from '../DataRefresher';
import { db } from '../indexeddb/db';

export function useRequiredTables(tableNames = []) {
    const dispatch = useDispatch();
    const [retryCount, setRetryCount] = useState({});
    const tables = useSelector(state => state.tableLoading.tables);
    const tableStates = useMemo(() => {
        return tables || {};
    }, [tables]);
    const bearer = useSelector(state => state.user.bearer);
    const MAX_RETRIES = 2;

    // Check for stale data only for the specified tables
    useEffect(() => {
        if (!tableStates || Object.keys(tableStates).length === 0) return;
        
        const staleTables = tableNames.filter(name => 
            tableStates[name] && 
            !tableStates[name].isLocalOnly && 
            !tableStates[name].staleDataChecked // Add a flag to prevent infinite checks
        );
        
        if (staleTables.length > 0) {
            staleTables.forEach(name => {
                dispatch(refreshStaleData(name));
            });
        }
    }, [tableNames, tableStates, dispatch]);

    // Only track if tables exist and their endpoints
    const tableMetadata = useMemo(() => {
        if (!tableStates || !tableNames) return {};
        
        return tableNames.reduce((acc, name) => {
            if (tableStates[name]) {
                acc[name] = {
                    endpoint: tableStates[name].endpoint,
                    loadingComplete: tableStates[name].loadingComplete
                };
            }
            return acc;
        }, {});
    }, [tableNames, tableStates]);

    const loadingLock = useRef(new Set());

    useEffect(() => {
        async function loadTable(tableName) {
            // Skip if already loaded successfully or currently loading
            if (tableMetadata[tableName].loadingComplete || 
                loadingLock.current.has(tableName)) return;
            
            // Skip if max retries reached
            if (retryCount[tableName] >= MAX_RETRIES) {
                console.error(`Max retries reached for table ${tableName}`);
                return;
            }

            loadingLock.current.add(tableName);
            
            const timing = {
                fetch: 0,
                parse: 0,
                dbWrite: 0
            };
            
            try {
                // Time the fetch
                const fetchStart = performance.now();
                const response = await fetch(tableMetadata[tableName].endpoint, {
                    headers: {
                        authorization: bearer,
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    }
                });
                timing.fetch = performance.now() - fetchStart;

                if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);

                // Time the JSON parsing
                const parseStart = performance.now();
                const data = await response.json();
                timing.parse = performance.now() - parseStart;

                // Time the DB operation
                const dbStart = performance.now();
                await batchedBulkPut(db.table(tableName), data);
                timing.dbWrite = performance.now() - dbStart;

                dispatch(setTableStatus({ tableName, status: 'success' }));

                console.log(`Table ${tableName} timing:`, {
                    fetch: timing.fetch.toFixed(2) + 'ms',
                    parse: timing.parse.toFixed(2) + 'ms',
                    dbWrite: timing.dbWrite.toFixed(2) + 'ms',
                    total: (performance.now() - fetchStart).toFixed(2) + 'ms'
                });
            } catch (error) {
                console.error(`Error loading ${tableName}:`, error);
                dispatch(setTableStatus({ 
                    tableName, 
                    status: 'error',
                    errorMessage: error.message
                }));
                
                // Increment retry count
                setRetryCount(prev => ({
                    ...prev,
                    [tableName]: (prev[tableName] || 0) + 1
                }));
            } finally {
                loadingLock.current.delete(tableName);
            }
        }

        // Handle local-only tables first
        Object.keys(tableMetadata).forEach(name => {
            if (tableStates[name].isLocalOnly && !tableMetadata[name].loadingComplete) {
                dispatch(setTableStatus({ tableName: name, status: 'success' }));
            }
        });

        // Only load tables that haven't been loaded successfully and aren't local-only
        const tablesToLoad = Object.keys(tableMetadata).filter(name => {
            const currentRetries = retryCount[name] || 0;
            return !tableMetadata[name].loadingComplete && 
                   currentRetries < MAX_RETRIES && 
                   !tableStates[name].isLocalOnly;
        });

        if (tablesToLoad.length > 0 && bearer) {
            Promise.all(tablesToLoad.map(loadTable));
        }
    }, [tableMetadata, bearer, retryCount, dispatch]);

    // Add refresh function to return value
    const refreshTables = useCallback((tableNamesToRefresh = tableNames) => {
        tableNamesToRefresh.forEach(tableName => {
            dispatch(refreshTable({ tableName }));
            setRetryCount(prev => ({ ...prev, [tableName]: 0 })); // Reset retry count
        });
    }, [dispatch, tableNames]);

    return {
        isLoading: tableNames.some(name => tableStates[name]?.status === 'pending'),
        errors: tableNames
            .filter(name => tableStates[name]?.status === 'error')
            .map(name => ({
                tableName: name,
                message: tableStates[name]?.errorMessage
            })),
        isReady: tableNames.every(name => tableStates[name]?.loadingComplete),
        refreshTables,
        lastRefreshed: tableNames.length > 0 ? Math.min(...tableNames.map(name => 
            new Date(tableStates[name]?.lastRefreshed || 0).getTime()
        )) : Date.now()
    };
} 