export var localTables = {
    CHANGE_LOG: 'DATA_TABLE,HASH_VALUE',
    CHECKINS: 'INDEX,DATE',
    CONTACTS: 'INDEX,NAME',
    COUNTRIES: 'INDEX,NAME',
    ENGAGEMENT: 'INDEX,CONTINDEX,CLIENTNAME,STAFF,CLIENTSTATUS',
    ENTERED_CLIENTS: 'KEY,STATUS,BOI_CLEARED,TIMESUBMITTED',
    ENTITY: 'INDEX,NAME',
    HOMEROOM: 'INDEX,STAFFINDEX,EMPLOYEE',
    INDUSTRIES: 'INDEX,NAME',
    JOBS: 'INDEX,CONTINDEX,JOB_NAME',
    JOB_TEMPLATES: 'INDEX,TYPE,NAME',
    MORALE: 'INDEX,DATE,STAFF',
    NEW_CLIENTS: 'requestNumber,status',
    OFFICES: 'INDEX,NAME',
    REQUESTS: 'INDEX,DATE,SUBMIT_USER,REQUEST_STATUS',
    REVIEWS: 'INDEX,REVIEW_USER,REVIEW_LEVEL,DATE,SUBMIT_USER',
    REVIEWS_GOALS: 'INDEX',
    SERVICES: 'INDEX,SERVNAME,SERVORGANISATION,SERVPERSON',
    STAFF: 'INDEX,STAFFINDEX,EMPLOYEE,HOMEROOM',
    TIME_CALENDAR: 'INDEX,STAFFINDEX',
    TIME_COMPLIANCE: 'INDEX,STAFFINDEX,COMPLIANT_PERCENT',
    TIME_STREAK: 'INDEX,STAFFINDEX,MIN_FALSE,MAX_TRUE,CURRENT_STREAK',
    UNPAID_AR: 'id++,UNPAID_INVOICE,CLIENT_PARTNER,CLIENT_MANAGER,CLIENT,OFFICE,CLIENT_PARTNER_IDX,CLIENT_MANAGER_IDX,CLIENT_PARTNER_DEPT,CLIENT_MANAGER_DEPT,AGING_PERIOD',
    USER_WIP: 'INDEX,TYPE',
    WIP: 'INDEX,CONTINDEX,CLIENT,CLIENT_PARTNER,CLIENT_MANAGER,JOBIDX,JOB_NAME,JOB_PARTNER,JOB_MANAGER,SERVICETITLE,WIPHOURS,WIPAMOUNT,WIPOUTSTANDING,OUTSTANDING_CURRENT,OUTSTANDING_31_60,OUTSTANDING_61_90,OUTSTANDING_OVERDUE'
};
