import { useEffect, lazy, Suspense } from "react";
import { useSelector, useDispatch } from "react-redux";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { isEqual, omit } from 'lodash';

import { PageLayout } from "../../components/Nav";
import {
    db,
} from "../../utils/indexeddb/db";
import { forceRefreshAllTables } from "../../utils/redux/tableLoadingSlice";
import { setUser } from "../../utils/redux/loginSlice"
import { incrementSessionCounter } from "../../utils/redux/globalsSlice"

import { MyResponsibleWIP } from "../reports/wip/WIPreports";
import NCTO from "../apps/NCTO/NCTO";
const Home = lazy(() => import("../Home"));
const Morale = lazy(() => import("../people/Morale/Morale"));
const Review = lazy(() => import("../people/Review/Review"));
const Request = lazy(() => import("../people/Request/Request"))
const NCTOLandingPage = lazy(() => import("../apps/NCTO/LandingPage"));
const Approval = lazy(() => import("../apps/NCTO/ApprovalPage/Approval"));
const ApprovalListPage = lazy(() =>
    import("../apps/NCTO/ApprovalPage/ApprovalListPage")
);
const BOI = lazy(() => import("../apps/NCTO/BOI"));
const Reject = lazy(() => import('../apps/NCTO/Reject'))

const TeamReviews = lazy(() => import("../reports/hr/TeamReviews"))
const FirmReviews = lazy(() => import("../reports/hr/FirmReviews"))
const FirmMorale = lazy(() => import("../reports/hr/FirmMorale"))
const TeamMorale = lazy(() => import("../reports/hr/TeamMorale"))
const FirmRequests = lazy(() => import("../reports/hr/FirmRequests"))


const FirmTime = lazy(() => import("../reports/time/FirmTime"))
const SelfTime = lazy(() => import("../reports/time/SelfTime"))

const Available = lazy(() => import("../available/Available"))

const VersionReleases = lazy(() => import("../reports/VersionReleases"))

function RoutesContent({ setShowHelp }) {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);
    const bearer = useSelector((state) => state.user.bearer);
    const { apiRoot, sessionUUID, sessionCounter } = useSelector(state => state.globals);

    // console.log('bearer', bearer)

    useEffect(() => {
        console.log('sessionCounter', sessionCounter)
        console.log('sessionUUID', sessionUUID)

        fetch(`${apiRoot}/api/tracking/login/`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: bearer,
            },
            body: JSON.stringify({
                user: user.STAFFINDEX,
                page: window.location.pathname,
                sessionUUID: sessionUUID,
                sessionCounter: sessionCounter
            })
        });

        // Only run this API call if we're on the root path
        if (sessionCounter <= 1) {
            const emailToFetch = new URLSearchParams({
                email: user.STAFF_EMAIL.toUpperCase()
            });

            fetch(`${apiRoot}/api/staff/me?${emailToFetch}`, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "x-functions-key":
                        "5cvbp_zFyZsTV9J8dqHD7NSkHHnUxyqR8dM0gSP5RYLfAzFuhVAZHQ==",
                    Authorization: bearer,
                    // "Access-Control-Allow-Origin": "https://bmss.magnify-app.com/"
                },
            })
                .then(response => response.json())
                .then(fetchedUser => {
                    console.log('fetchedUser', fetchedUser)

                    // if (fetchedUser.ALERTS) {
                    //     const { ALERTS, ...dataWithoutAlerts } = fetchedUser;
                    //     fetchedUser = dataWithoutAlerts;
                    // }
                    const currentUserCompare = omit(user, ['bearer', 'authenticated']);
                    const fetchedUserCompare = omit(fetchedUser, ['bearer', 'authenticated']);

                    if (!isEqual(currentUserCompare, fetchedUserCompare)) {
                        console.log('User data has changed. Updating user state.');
                        // Log the differences between current user and fetched user
                        const currentKeys = Object.keys(currentUserCompare);
                        const fetchedKeys = Object.keys(fetchedUserCompare);
                        
                        // Find keys that exist in both objects but have different values
                        const changedKeys = currentKeys.filter(key => 
                            fetchedKeys.includes(key) && 
                            !isEqual(currentUserCompare[key], fetchedUserCompare[key])
                        );
                        
                        // Find keys that exist only in the fetched user data
                        const newKeys = fetchedKeys.filter(key => !currentKeys.includes(key));
                        
                        // Find keys that exist only in the current user data
                        const removedKeys = currentKeys.filter(key => !fetchedKeys.includes(key));
                        
                        console.log('Changed keys:', changedKeys);
                        console.log('New keys:', newKeys);
                        console.log('Removed keys:', removedKeys);
                        
                        if (changedKeys.length > 0) {
                            console.log('Changed values:');
                            changedKeys.forEach(key => {
                                console.log(`${key}:`, {
                                    current: currentUserCompare[key],
                                    fetched: fetchedUserCompare[key]
                                });
                            });
                        }
                        dispatch(setUser({
                            ...fetchedUser,
                            bearer: bearer,
                            authenticated: 'authenticated'
                        }));
                    }
                })
                .catch(error => {
                    console.error('Error fetching user data:', error);
                });
        }

        dispatch(incrementSessionCounter())

    }, []); // Still keeping empty dependency array for mount-only behavior

    return (
        <PageLayout setShowHelp={setShowHelp}>
            <Suspense fallback={<br></br>}>
                <Routes>
                    <Route exact path="/" element={<Home />} />
                    <Route
                        exact
                        path="/reports/wip/responsible"
                        element={<MyResponsibleWIP />}
                    />
                    <Route
                        exact
                        path='/reports/reviews/team'
                        element={<TeamReviews />}
                    />
                    <Route
                        exact
                        path='/reports/reviews/firm'
                        element={<FirmReviews />}
                    />
                    <Route
                        exact
                        path='/reports/morale/firm'
                        element={<FirmMorale />}
                    />
                    <Route
                        exact
                        path='/reports/morale/team'
                        element={<TeamMorale />}
                    />
                    <Route
                        exact
                        path='/reports/requests/firm'
                        element={<FirmRequests />}
                    />
                    <Route
                        exact
                        path='/reports/time/firm'
                        element={<FirmTime />}
                    />
                    <Route
                        exact
                        path='/reports/time/self'
                        element={<SelfTime />}
                    />
                    <Route
                        exact
                        path='/available'
                        element={<Available />}
                    />
                    <Route exact path="/people/morale" element={<Morale />} />
                    <Route exact path="/people/reviews" element={<Review />} />
                    <Route exact path="/people/requests" element={<Request />} />

                    <Route exact path="/apps/ncto" element={<NCTOLandingPage />} />
                    <Route exact path="/apps/ncto/form" element={<NCTO />} />
                    <Route
                        exact
                        path="/apps/ncto/approve/:office"
                        element={<ApprovalListPage />}
                    />
                    <Route
                        path="/apps/ncto/approve/:office/:key"
                        element={<Approval />}
                    />
                    <Route
                        path='/apps/ncto/reject/:office/:key'
                        element={<Reject />}
                    />
                    <Route exact path="/apps/ncto/boi" element={<BOI />} />

                    <Route exact path="/release-notes" element={<VersionReleases />} />
                </Routes>
            </Suspense>
        </PageLayout>
    );
}

export default function UserRouter(props) {
    const version = "5.2.0"
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);

    useEffect(() => {
        const clearAllStorage = async () => {
            try {
                console.log('Starting storage clear process...');

                // Force refresh all tables first
                dispatch(forceRefreshAllTables());
                console.log('Forced refresh of all tables');

                // Clear IndexedDB first
                await db.delete();
                console.log('IndexedDB cleared');

                // Clear all localStorage items
                const specificKeys = ['tableLoading', 'lastRefreshed'];
                specificKeys.forEach(key => {
                    if (localStorage.getItem(key)) {
                        console.log(`Clearing ${key} from localStorage`);
                        localStorage.removeItem(key);
                    }
                });
                localStorage.clear();
                console.log('localStorage cleared');

                // Clear sessionStorage
                sessionStorage.clear();
                console.log('sessionStorage cleared');

                // Clear cache if possible
                if ('caches' in window) {
                    const cacheKeys = await caches.keys();
                    await Promise.all(
                        cacheKeys.map(key => caches.delete(key))
                    );
                    console.log('Browser caches cleared');
                }

                // Verify localStorage is actually clear
                const isLocalStorageCleared = localStorage.length === 0;
                console.log('localStorage is cleared:', isLocalStorageCleared);

                // Only set new version after confirming clear
                if (isLocalStorageCleared) {
                    localStorage.setItem('version', version);
                    console.log('New version set:', version);
                }

                // Add a delay to ensure all operations complete
                await new Promise(resolve => setTimeout(resolve, 500));

                // Final verification
                console.log('Final localStorage state:', {
                    tableLoading: localStorage.getItem('tableLoading'),
                    lastRefreshed: localStorage.getItem('lastRefreshed'),
                    version: localStorage.getItem('version')
                });
                dispatch(setUser({
                    ...user,
                    bearer: "ghnkDUtbtivVZqLEvKw0yGPshZf+dY2gwFtER9qktvWuLIdQtY9as8S1lV+q6Rs3gDkVYQEI4+odQ23/ojBdEpyf2Huc/a0dzh6HhQcYHCuPe5e8/YlPIYejXmrTOjGkILz24ZJTitUvA8eUzQXgyB1Vj3dmHyUC/hm0kohR+FVetRtMKaV3fVoTTH3UmD5x/9DK8HVcTQiepxJ7uqPB2b8Cg33VSrryJKZFAYLroFtS37ysjhuOpSGc90piQuHwwh7sHNhtY05lc4vHzuZYqa1fkUn5PmRHo7acBwqyFfVFgTvLkjwca3VcBH14op8zkZtR9ZrfHmM4nwfn/87tQg=="
                }));

                // Reload page
                window.location.reload();
            } catch (error) {
                console.error('Error clearing storage:', error);
                // Attempt recovery
                try {
                    await db.delete();
                    localStorage.clear();
                    sessionStorage.clear();

                    // Explicit removal of problematic items
                    localStorage.removeItem('tableLoading');
                    localStorage.removeItem('lastRefreshed');

                    localStorage.setItem('version', version);

                    await new Promise(resolve => setTimeout(resolve, 500));
                    window.location.reload();
                } catch (secondError) {
                    console.error('Recovery attempt failed:', secondError);
                }
            }
        };

        // Only clear if versions don't match
        const cachedVersion = localStorage.getItem('version');
        const [cachedMajor, cachedMinor, cachedPatch] = cachedVersion?.split('.') || [];
        const currentVersion = version.split('.');
        const [currentMajor, currentMinor, currentPatch] = currentVersion;

        if (cachedMajor !== currentMajor || cachedMinor !== currentMinor) {
            console.log(`Clearing storage, old version: ${localStorage.getItem('version')}`);
            clearAllStorage();
        }
    }, []);

    return (
        <Router>

            <RoutesContent setShowHelp={props.setShowHelp} />
        </Router>
    );
}