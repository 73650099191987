import { useEffect, useState } from "react";
// import { PageLayout } from "./components/Nav";
import { PrimeReactProvider } from "primereact/api";
import { Help } from "./components/Help";
import UserRouter from "./pages/home/UserRouter";
import NonUserPage from "./pages/home/NonUserPage";
import { Loading } from "./pages/home/Loading";

// import { isEqual } from 'lodash'
// import { RenderCounter } from "./pages/apps/NCTO/UtilityComponets";

import "./style/primereact-theme.css";
import "./style/App.css";
import "./style/theme.css";
import "/node_modules/primeflex/primeflex.css";
import "/node_modules/bootstrap/dist/css/bootstrap.css";

// import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { useSelector } from "react-redux";
// import { setUser } from "./utils/redux/loginSlice";
// import { callMsGraph } from "./graph";
// import ClipLoader from "react-spinners/ClipLoader";
// import RingLoader from "react-spinners/RingLoader";

// import {
//     DataRefresher,
//     db,
//     GetRemoteVersions,
//     //   UpdateLessChangelog,
// } from "./utils/indexeddb/db";

import { loginRequest } from "./authConfig";
// import { MyResponsibleWIP } from "./pages/reports/wip/WIPreports";
// // import { MyWIP } from "./pages/reports/wip/MyWIP";
// import NCTO from "./pages/apps/NCTO/NCTO";
// // const Reports = lazy(() => import("./pages/reports/Reports"));
// const Home = lazy(() => import("./pages/Home"));
// // import Reports from "./pages/reports/Reports";
// // const FirmAR = lazy(() => import("./pages/reports/ar/FirmAR"));
// // const DeptAR = lazy(() => import("./pages/reports/ar/DeptAR"));
// // const RespAR = lazy(() => import("./pages/reports/ar/RespAR"));
// // const Member = lazy(() => import("./pages/reports/powerbi/Member"));
// const Morale = lazy(() => import("./pages/people/Morale/Morale"));
// const Review = lazy(() => import("./pages/people/Review/Review"));
// const Request = lazy(() => import("./pages/people/Request/Request"))
// // const NCTO = lazy(() => import("./pages/apps/NCTO/NCTO"))
// const NCTOLandingPage = lazy(() => import("./pages/apps/NCTO/LandingPage"));
// const Approval = lazy(() => import("./pages/apps/NCTO/Approval"));
// const ApprovalListPage = lazy(() =>
//     import("./pages/apps/NCTO/ApprovalListPage")
// );
// const BOI = lazy(() => import("./pages/apps/NCTO/BOI"));
// const Reject = lazy(() => import('./pages/apps/NCTO/Reject'))

// const TeamReviews = lazy(() => import('./pages/reports/hr/TeamReviews'))
// const FirmReviews = lazy(() => import('./pages/reports/hr/FirmReviews'))
// const FirmMorale = lazy(() => import('./pages/reports/hr/FirmMorale'))
// const TeamMorale = lazy(() => import('./pages/reports/hr/TeamMorale'))
// const FirmRequests = lazy(() => import('./pages/reports/hr/FirmRequests'))


// const FirmTime = lazy(() => import('./pages/reports/time/FirmTime'))
// const SelfTime = lazy(() => import('./pages/reports/time/SelfTime'))

// const Available = lazy(() => import('./pages/available/Available'))

// const Loading = ({ dataRefreshingState }) => {
//     const location = useLocation();
    
//     if (!dataRefreshingState || location.pathname !== '/') return null;

//     return (
//         <>
//             <div style={{
//                 position: "fixed",
//                 top: 0,
//                 left: 0,
//                 right: 0,
//                 bottom: 0,
//                 backgroundColor: "rgba(255, 255, 255, 0.7)",
//                 zIndex: 1000,
//                 display: "flex",
//                 justifyContent: "center",
//                 alignItems: "center"
//             }}>
//                 <ClipLoader
//                     color="Black"
//                     loading={true}
//                     size={50}
//                     aria-label="Loading"
//                     data-testid="loader"
//                 />
//             </div>
//         </>
//     );
// };

// function ActiveUserRoute(props) {
//     const version = "2.0.4"

//     useEffect(() => {
//         const clearAllStorage = async () => {
//             try {
//                 // Clear localStorage and sessionStorage
//                 localStorage.clear();
//                 sessionStorage.clear();
                
//                 // Clear IndexedDB
//                 await db.delete(); // Assuming db is your Dexie instance
                
//                 // Set new version
//                 localStorage.setItem('version', version);
                
//                 // Reload page
//                 window.location.reload();
//             } catch (error) {
//                 console.error('Error clearing storage:', error);
//                 // Attempt recovery
//                 localStorage.clear();
//                 sessionStorage.clear();

//                 await db.delete();

//                 localStorage.setItem('version', version);
//                 window.location.reload();
//             }
//         };

//         // Only clear if versions don't match
//         if (localStorage.getItem('version') !== version) {
//             console.log(`Clearing storage, old version: ${localStorage.getItem('version')}`);
//             clearAllStorage();
//         }
//     }, []);

//     const authenticated = useSelector((state) => state.user.authenticated);
//     const user = useSelector((state) => state.user.STAFFINDEX);
//     const bearer = useSelector((state) => state.user.bearer);
//     const apiRoot = useSelector(state => state.globals.apiRoot)

//     if (authenticated === 'authenticated' || authenticated === true) {
//         // if (true === true) {
//         fetch(`${apiRoot}/api/tracking/login/${user}`, {
//             method: "PUT",
//             headers: {
//                 Accept: "application/json",
//                 "Content-Type": "application/json",
//                 Authorization: bearer,
//             },
//         })

//         return (
//             <Router>
//                 <Loading dataRefreshingState={props.dataRefreshingState} />
//                 <PageLayout setShowHelp={props.setShowHelp}>
//                     <Suspense fallback={<br></br> /*<Loading loader='ring' />*/}>
//                         <Routes>
//                             <Route
//                                 exact
//                                 path="/"
//                                 element={
//                                     <Home
//                                         setGraph={props.setGraph}
//                                         inProgress={props.inProgress}
//                                     />
//                                 }
//                             />
//                             {/* <Route exact path="/reports" element={<Reports />} /> */}
//                             {/* <Route exact path="/reports/ar/firm" element={<FirmAR />} /> */}
//                             {/* <Route exact path="/reports/ar/dept" element={<DeptAR />} /> */}
//                             {/* <Route
//                 exact
//                 path="/reports/ar/responsible"
//                 element={<RespAR />}
//               /> */}

//                             <Route
//                                 exact
//                                 path="/reports/wip/responsible"
//                                 element={<MyResponsibleWIP />}
//                             />
//                             <Route
//                                 exact
//                                 path='/reports/reviews/team'
//                                 element={<TeamReviews />}
//                             />
//                             <Route
//                                 exact
//                                 path='/reports/reviews/firm'
//                                 element={<FirmReviews />}
//                             />
//                             <Route
//                                 exact
//                                 path='/reports/morale/firm'
//                                 element={<FirmMorale />}
//                             />
//                             <Route
//                                 exact
//                                 path='/reports/morale/team'
//                                 element={<TeamMorale />}
//                             />
//                             <Route
//                                 exact
//                                 path='/reports/requests/firm'
//                                 element={<FirmRequests />}
//                             />
//                             <Route
//                                 exact
//                                 path='/reports/time/firm'
//                                 element={<FirmTime />}
//                             />
//                             <Route
//                                 exact
//                                 path='/reports/time/self'
//                                 element={<SelfTime />}
//                             />
//                             <Route
//                                 exact
//                                 path='/available'
//                                 element={<Available />}
//                             />
//                             {/* <Route exact path="/reports/wip/self" element={<MyWIP />} /> */}
//                             {/* <Route exact path="/reports/wip/dept" element={<DeptWIP />} /> */}
//                             {/* <Route exact path="/reports/wip/team" element={<TeamWIP />} /> */}

//                             {/* <Route
//                 exact
//                 path="/reports/powerbi/member"
//                 element={<Member />}
//               /> */}
//                             <Route exact path="/people/morale" element={<Morale />} />
//                             <Route exact path="/people/reviews" element={<Review />} />
//                             <Route exact path="/people/requests" element={<Request />} />
//                             {/* <Route exact path="/apps/bingo" element={<Bingo />} />  */}

//                             <Route exact path="/apps/ncto" element={<NCTOLandingPage />} />
//                             <Route exact path="/apps/ncto/form" element={<NCTO />} />
//                             <Route
//                                 exact
//                                 path="/apps/ncto/approve/:office"
//                                 element={<ApprovalListPage />}
//                             />
//                             <Route
//                                 path="/apps/ncto/approve/:office/:key"
//                                 element={<Approval />}
//                             />
//                             <Route
//                                 path='/apps/ncto/reject/:office/:key'
//                                 element={<Reject />}
//                             />
//                             <Route exact path="/apps/ncto/boi" element={<BOI />} />

//                             {/* <Route exact path='/reports/powerbi/hr' Component={} /> */}
//                         </Routes>
//                     </Suspense>
//                 </PageLayout>
//             </Router>
//         );
//     } else if (authenticated === 'failed') {
//         return (
//             <section>
//                 <p>Uh oh! Looks like you are not a registered user with this company</p>
//                 <button onClick={() => {
//                     localStorage.clear()
//                     sessionStorage.clear()
//                     window.location.reload()
//                 }}>Click here to reset and login again</button>
//             </section>
//         );
//     } else {
//         return <ClipLoader loading={true} />;
//     }
// }

export default function App() {
    // const [graphData, setGraphData] = useState(null);
    const [showHelp, setShowHelp] = useState(false);
    // const { inProgress } = useMsal();
    const { authenticated } = useSelector((state) => state.user);
    const [authStatus, setAuthStatus] = useState(authenticated || 'pending');
    // const bearer = useSelector((state) => state.user.bearer);
    // const apiRoot = useSelector(state => state.globals.apiRoot)
    // const dispatch = useDispatch();

    useEffect(() => {
        setAuthStatus(authenticated || 'pending');
    }, [authenticated]);

    // RenderCounter("Main App");
    // useEffect(() => {
    //     if (
    //         !graphData &&
    //         authStatusRef.current === 'pending' &&
    //         inProgress === InteractionStatus.None
    //     ) {
    //         instance
    //             .handleRedirectPromise()
    //             .then((tokenResponse) => {
    //                 if (tokenResponse) {
    //                     callMsGraph(tokenResponse.accessToken)
    //                         .then((response) => {
    //                             setGraphData(response);
    //                         })
    //                         .then(() => {
    //                             let email = tokenResponse.account.username.toUpperCase();
    //                             if (email.includes("@ABACUSTECHNOLOGIES.COM")) {
    //                                 email = "kfluker@BMSS.COM".toUpperCase();
    //                             }

    //                             if (email === 'jshank@magnifyapponline.onmicrosoft.com'.toUpperCase()) {
    //                                 email = 'gfatic@bmss.com'.toUpperCase()
    //                             }

    //                             if (email === 'wbranning@magnifyapponline.onmicrosoft.com'.toUpperCase()) {
    //                                 email = 'hunter.smith@bmss.com'.toUpperCase()
    //                             }

    //                             // if (email === 'jeremyshank@bmss.com'.toUpperCase()) {
    //                             //     email = 'ebutler@bmss.com'.toUpperCase()
    //                             // }

    //                             const emailRegex = /^[a-zA-Z0-9._]+@bmss\.com$/i;
    //                             const isValidBmssEmail = emailRegex.test(email);

    //                             if (isValidBmssEmail) {
    //                                 const emailToFetch = new URLSearchParams({
    //                                     email: email
    //                                 });
    //                                 return fetch(`${apiRoot}/api/staff/me?${emailToFetch}`, {
    //                                     method: "GET",
    //                                     headers: {
    //                                         Accept: "application/json",
    //                                         "Content-Type": "application/json",
    //                                         "x-functions-key":
    //                                             "5cvbp_zFyZsTV9J8dqHD7NSkHHnUxyqR8dM0gSP5RYLfAzFuhVAZHQ==",
    //                                         Authorization: bearer,
    //                                         "Access-Control-Allow-Origin": "https://bmss.magnify-app.com/"
    //                                     },
    //                                 })
    //                                     .then(res => {
    //                                         if (!res.ok) {
    //                                             throw new Error("Not a registered user");
    //                                         }
    //                                         return res.json();
    //                                     })
    //                                     .then(data => {
    //                                         authStatusRef.current = 'authenticated';
    //                                         dispatch(setUser({ authenticated: 'authenticated', ...data, bearer: bearer }));
    //                                     });
    //                             }
    //                             throw new Error("Not an approved email address.");
    //                         })
    //                         .catch((err) => {
    //                             console.error(err);
    //                             authStatusRef.current = 'failed';
    //                             dispatch(setUser({
    //                                 authenticated: 'failed',
    //                                 message: "Authentication failed"
    //                             }));
    //                         });
    //                 } else {
    //                     throw new Error("No token response!");
    //                 }
    //             })
    //             .catch((err) => {
    //                 console.error(err);
    //             });
    //     } else if (authStatusRef.current === 'authenticated') {
    //         let email = USER.STAFF_EMAIL.toUpperCase();
    //         let emailToFetch = new URLSearchParams({
    //             email: email
    //         });
    //         fetch(`${apiRoot}/api/staff/me?${emailToFetch}`, {
    //             method: "GET",
    //             headers: {
    //                 Accept: "application/json",
    //                 "Content-Type": "application/json",
    //                 "x-functions-key":
    //                     "5cvbp_zFyZsTV9J8dqHD7NSkHHnUxyqR8dM0gSP5RYLfAzFuhVAZHQ==",
    //                 Authorization: bearer,
    //                 "Access-Control-Allow-Origin": "https://bmss.magnify-app.com/"
    //             },
    //             credentials: 'include',
    //             mode: 'cors',
    //         }).then(res => {
    //             return res.json()
    //         }).then(data => {
    //             if (!isEqual(data, USER)) {
    //                 dispatch(
    //                     setUser({ authenticated: 'authenticated', ...data, bearer: bearer })
    //                 );
    //             }
    //         })
    //     }
    // }, [inProgress, graphData, instance, dispatch, bearer, apiRoot]);

    return (
        <PrimeReactProvider>
            {authStatus === 'failed' ? (
                <>
                    <NonUserPage 
                        setShowHelp={setShowHelp}
                    />
                    <Help visible={showHelp} showCallBack={setShowHelp} />
                </>
            ) : authStatus === 'pending' ? (
                <MsalAuthenticationTemplate 
                    interactionType={InteractionType.Redirect}
                    authenticationRequest={loginRequest}
                >
                    <Loading />
                </MsalAuthenticationTemplate>
            ) : authStatus === 'authenticated' ? (
                <>
                    <UserRouter
                        setShowHelp={setShowHelp}
                    />
                    <Help visible={showHelp} showCallBack={setShowHelp} />
                </>
            ) : null}
        </PrimeReactProvider>
    );
}
