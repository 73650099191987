import ReactEChart from 'echarts-for-react';
import { DataCards } from '../Cards';
// import { Fragment } from 'react';

export function UnpaidARChart(chartData) {

    if (chartData.chartData) {
        const totalOutstanding = chartData.chartData.OUTSTANDING;
        const percentCurr = chartData.chartData.PERCENT_CURR === 0 ? 0 : chartData.chartData.PERCENT_CURR;
        const percent31 = chartData.chartData.PERCENT_31_60;
        const percent61 = chartData.chartData.PERCENT_61_90;
        const percentOverdue = chartData.chartData.PERCENT_OVERDUE;

        const pieChartOptions = {
            tooltip: {
                trigger: 'item'
            },
            graphic: {
                elements: [{
                    type: 'text',
                    left: 'center',
                    top: 'middle',
                    z: 999,
                    style: {
                        text: chartData.centerLabel,
                        textAlign: 'center',
                        fontSize: 26,
                    }
                }]
            },
            series: [
                {
                    name: 'Unpaid AR',
                    type: 'pie',
                    radius: ['55%', '95%'],
                    avoidLabelOverlap: true,
                    startAngle: 180,
                    label: {
                        show: false,
                        position: 'center'
                    },
                    emphasis: {
                        label: {
                            position: 'inside',
                            show: true,
                            fontSize: 20,
                            fontWeight: 'bold',

                            formatter(param) {
                                // correct the percentage
                                return param.name + ' (' + param.percent + '%)';
                            },
                            itemStyle: {
                                z: 999,
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    },
                    data: [
                        { value: percentCurr, name: 'Current' },
                        { value: percent31, name: '31-60 Days', itemStyle: {} },
                        { value: percent61, name: '61-90 Days', itemStyle: {} },
                        { value: percentOverdue, name: 'Overdue', itemStyle: {} },
                    ],
                }
            ]
        }
        return (<ReactEChart option={pieChartOptions} className='upaid-ar-pie-chart' style={{ height: '200px', width: '275px' }} />)
    }
}

export function ResponsibleWIPPie({chartData, title}) {
    // console.log(chartData)

    if (chartData) {
    
        const current = chartData.reduce((total, obj) => {
            return total + obj.OUTSTANDING_CURRENT
        }, 0)
        
        const overdue = chartData.reduce((total, obj) => {
            return total + obj.OUTSTANDING_CURRENT
        }, 0)
        
        const thirty = chartData.reduce((total, obj) => {
            return total + obj.OUTSTANDING_31_60
        }, 0)
        
        const sixty = chartData.reduce((total, obj) => {
            return total + obj.OUTSTANDING_61_90
        }, 0)

        const pieChartOptions = {
            tooltip: {
                trigger: 'item'
            },
            graphic: {
                elements: [{
                    type: 'text',
                    left: 'center',
                    top: 'middle',
                    z: 999,
                    style: {
                        text: '',
                        textAlign: 'center',
                        fontSize: 26,
                    }
                }]
            },
            series: [
                {
                    name: title,
                    type: 'pie',
                    radius: ['55%', '95%'],
                    avoidLabelOverlap: true,
                    startAngle: 180,
                    label: {
                        show: false,
                        position: 'center'
                    },
                    emphasis: {
                        label: {
                            position: 'inside',
                            show: false,
                            fontSize: 20,
                            fontWeight: 'bold',

                            // formatter(param) {
                            //     // correct the percentage
                            //     return `${param.name} $${param.value.toFixed(2)}`;
                            // },

                            itemStyle: {
                                z: 999,
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    },
                    data: [
                        { value: current.toFixed(2), name: 'Current', itemStyle: { color: "#5C6FC5" } },
                        { value: thirty.toFixed(2), name: '31-60 Days', itemStyle: { color: "#95CB77" } },
                        { value: sixty.toFixed(2), name: '61-90 Days', itemStyle: { color: "#F4CA5E" } },
                        { value: overdue.toFixed(2), name: 'Overdue', itemStyle: { color: "#FF7070" } },
                    ],
                }
            ]
        }
        return (
            <div style={{width: 350}}>
                <h4>{title}</h4>
                <ReactEChart option={pieChartOptions} className='unpaid-ar-pie-chart' style={{ height: '200px', width: '275px'}} />
            </div>
            
        )
    }
}

export function RingGuageUtilization(props) {

}

export function ReviewsPie({exceeded, met, improvement, title}) {
    // console.log(chartData)
    // if (chartData) {

        // const exceeded = chartData.filter(rolo => rolo.RATING === 'Exceeded Expectations').length
        // const met = chartData.filter(rolo => rolo.RATING === 'Met Expectations').length
        // const improvement = chartData.filter(rolo => rolo.RATING === 'Needs Improvement').length
    
        // const current = chartData.reduce((total, obj) => {
        //     return total + obj.OUTSTANDING_CURRENT
        // }, 0)
        
        // const overdue = chartData.reduce((total, obj) => {
        //     return total + obj.OUTSTANDING_CURRENT
        // }, 0)
        
        // const thirty = chartData.reduce((total, obj) => {
        //     return total + obj.OUTSTANDING_31_60
        // }, 0)
        
        // const sixty = chartData.reduce((total, obj) => {
        //     return total + obj.OUTSTANDING_61_90
        // }, 0)

        const pieChartOptions = {
            tooltip: {
                trigger: 'item'
            },
            graphic: {
                elements: [{
                    type: 'text',
                    left: 'center',
                    top: 'middle',
                    z: 999,
                    style: {
                        text: '',
                        textAlign: 'center',
                        fontSize: 26,
                    }
                }]
            },
            series: [
                {
                    name: title,
                    type: 'pie',
                    radius: ['55%', '95%'],
                    avoidLabelOverlap: true,
                    startAngle: 180,
                    label: {
                        show: false,
                        position: 'center'
                    },
                    emphasis: {
                        label: {
                            position: 'inside',
                            show: false,
                            fontSize: 20,
                            fontWeight: 'bold',

                            // formatter(param) {
                            //     // correct the percentage
                            //     return `${param.name} $${param.value.toFixed(2)}`;
                            // },

                            itemStyle: {
                                z: 999,
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    },
                    data: [
                        { value: exceeded, name: 'Exceeded Expectations', itemStyle: { color: "#5C6FC5" } },
                        { value: met, name: 'Met Expectations', itemStyle: { color: "#F4CA5E" } },
                        { value: improvement, name: 'Needs Improvement', itemStyle: { color: "#FF7070" } },
                        // { value: overdue.toFixed(2), name: 'Overdue', itemStyle: { color: "#FF7070" } },
                    ],
                }
            ]
        }
        return (
            <div style={{width: 350}}>
                <h4>{title}</h4>
                <ReactEChart option={pieChartOptions} style={{ height: '200px', width: '275px'}} />
                {/* <div id="static-ar-cards">
                    <Cards level={objKey} metric='UNPAID_INVOICE' />
                    <DataCards value={exceeded} title='Exceeded Expectations' color='#5C6FC5' />
                    <DataCards value={met} title='Met Expectations' color='#F4CA5E' />
                    <DataCards value={"$" + sixty.toFixed(2).toString().replace(/\d(?=(\d{3})+\.)/g, '$&,')} title='61-90 Days' color='#F4CA5E' />
                    <DataCards value={improvement} title='Needs Improvement' color='#FF7070' />
                </div> */}
            </div>
            
        )
    // }
}
export function TimePie({hundred, ninety, eighty, under, title}) {

        const pieChartOptions = {
            tooltip: {
                trigger: 'item'
            },
            graphic: {
                elements: [{
                    type: 'text',
                    left: 'center',
                    top: 'middle',
                    z: 999,
                    style: {
                        text: '',
                        textAlign: 'center',
                        fontSize: 26,
                    }
                }]
            },
            series: [
                {
                    name: title,
                    type: 'pie',
                    radius: ['55%', '95%'],
                    avoidLabelOverlap: true,
                    startAngle: 180,
                    label: {
                        show: false,
                        position: 'center'
                    },
                    emphasis: {
                        label: {
                            position: 'inside',
                            show: false,
                            fontSize: 20,
                            fontWeight: 'bold',
                            itemStyle: {
                                z: 999,
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    },
                    data: [
                        { value: hundred, name: '100%', itemStyle: { color: "green" } },
                        { value: ninety, name: '90-99%', itemStyle: { color: "#5C6FC5" } },
                        { value: eighty, name: '80-89%', itemStyle: { color: "#F4CA5E" } },
                        { value: under, name: 'Under 80%', itemStyle: { color: "#FF7070" } },
                    ],
                }
            ]
        }
        return (
            <div style={{width: 350}}>
                {/* <h4>{title}</h4> */}
                <ReactEChart option={pieChartOptions} style={{ height: '200px', width: '275px'}} />
                {/* <div id="static-ar-cards">
                    <Cards level={objKey} metric='UNPAID_INVOICE' />
                    <DataCards value={exceeded} title='Exceeded Expectations' color='#5C6FC5' />
                    <DataCards value={met} title='Met Expectations' color='#F4CA5E' />
                    <DataCards value={"$" + sixty.toFixed(2).toString().replace(/\d(?=(\d{3})+\.)/g, '$&,')} title='61-90 Days' color='#F4CA5E' />
                    <DataCards value={improvement} title='Needs Improvement' color='#FF7070' />
                </div> */}
            </div>
            
        )
    // }
}
