import { useState, useRef, useEffect } from "react";
import { Controller } from "react-hook-form";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../../../utils/indexeddb/db";
import { SetCountry, setAddressValues } from "./UtilityComponets";
import ExistingClientsTable from "./ExistingClientsTable";
import { states /*countries*/ } from "./FlatData";

import { Dropdown } from "primereact/dropdown";
import { Panel } from "primereact/panel";
import { InputText } from "primereact/inputtext";
import { InputSwitch } from "primereact/inputswitch";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputMask } from "primereact/inputmask";
import { SelectButton } from "primereact/selectbutton";

export default function ContactDetails({
  panelClasses,
  panelState,
  onExpand,
  formControl,
  fieldData,
  setValue,
  getValues,
  watch,
  countries,
}) {
  const ref = useRef(null);

  useEffect(() => {
    if (panelState === true) {
      ref.current.expand();
    } else if (panelState === false) {
      ref.current.collapse();
    }
  }, [panelState]);

  const contactSameAsClient = watch("contactSameAsClient");

  useEffect(() => {
    contactSameAsClient &&
      setAddressValues("client", "contact", setValue, getValues);
  }, [contactSameAsClient, setValue, getValues]);

  const contactLocation = watch("contactLocation");
  const clientLocation = watch("clientLocation");

  const [sameAsClient, setSameAsClient] = useState(false);
  const [searchContactVis, setSearchContactVis] = useState(false);

  const originationStateOptions = [
    { label: "Domestic", value: "domestic" },
    { label: "International", value: "international" },
  ];

  const existingContacts = useLiveQuery(async () => {
    const contacts = await db.CONTACTS.orderBy("NAME").toArray();
    return contacts;
  });

  return (
    <Panel
      header={<span className="ncto-panel-title">Contact Details</span>}
      pt={{ content: { className: panelClasses } }}
      toggleable
      ref={ref}
      onExpand={onExpand}
    >
      <div id="first-name" className="w-3 px-2">
        <h6>First Name</h6>
        <Controller
          name="contactFirstName"
          rules={{ required: "Contact First Name Required." }}
          control={formControl}
          defaultValue={""}
          render={({ field }) => (
            <InputText
              className="w-full"
              id={field.value}
              placeholder="Enter First Name"
              value={field.value}
              onChange={(e) => field.onChange(e.target.value)}
            />
          )}
        />
      </div>

      <div id="last-name" className="w-3 px-2">
        <h6>Last Name</h6>
        <Controller
          name="contactLastName"
          rules={{ required: "Contact Last Nname Required." }}
          control={formControl}
          defaultValue={""}
          render={({ field }) => (
            <InputText
              className="w-full"
              id={field.name}
              placeholder="Enter Last Name"
              value={field.value}
              onChange={(e) => field.onChange(e.target.value)}
            />
          )}
        />
      </div>

      <div id="email" className="w-4 px-2">
        <h6>Email</h6>
        <Controller
          name="contactEmail"
          rules={{ required: "Contact Email Required." }}
          control={formControl}
          defaultValue={""}
          render={({ field }) => (
            <InputText
              className="w-full"
              id={field.name}
              placeholder="Enter Contact Email"
              value={field.value}
              onChange={(e) => field.onChange(e.target.value)}
            />
          )}
        />
      </div>

      <div id="phone-number" className="w-2 px-2">
        <h6>Phone Number</h6>
        <Controller
          name="contactPhoneNumber"
          rules={{ required: "Contact Phone Number Required." }}
          control={formControl}
          render={({ field }) => (
            <InputMask
              className="w-full"
              id={field.name}
              placeholder="Enter Phone Number"
              mask="999-999-9999"
              value={field.value}
              onChange={(e) => field.onChange(e.target.value)}
            />
          )}
        />
      </div>

      <div id="contact-address" className="w-7 px-2">
        <h6>Address</h6>
        <Controller
          name="contactAddress"
          rules={{ required: "Contact Address Required." }}
          control={formControl}
          defaultValue={sameAsClient ? fieldData.clientAddress : ""}
          render={({ field }) => (
            <InputText
              className="w-full"
              placeholder="Enter Address"
              value={sameAsClient ? fieldData.clientAddress : field.value}
              onChange={(e) => field.onChange(e.target.value)}
            />
          )}
        />
      </div>

      <div className="w-5 m-0 px-2">
        <h6>City</h6>
        <Controller
          name="contactCity"
          rules={{ required: "Contact City Required." }}
          control={formControl}
          defaultValue={sameAsClient ? fieldData.clientCity : ""}
          render={({ field }) => (
            <InputText
              className="w-full"
              id={field.name}
              placeholder="Enter City"
              value={sameAsClient ? fieldData.clientCity : field.value}
              onChange={(e) => field.onChange(e.target.value)}
              pt={{ root: { className: "w-full" } }}
            />
          )}
        />
      </div>

      <div id="origination-selection" className="w-auto px-1 align-self-end ">
        {/* <h6 className='w-full text-center'>New or Exsisting Client?</h6> */}
        <Controller
          name="contactLocation"
          defaultValue={sameAsClient ? clientLocation : contactLocation}
          control={formControl}
          render={({ field }) => (
            <SelectButton
              className="justify-content-center"
              id={field.name}
              value={sameAsClient ? clientLocation : field.value}
              onChange={(e) => {
                !sameAsClient && field.onChange(e.value);
                e.value === "domestic" &&
                  setValue("contactCountry", "United States");
              }}
              options={originationStateOptions}
              // pt={{button: {className:'w-8'}}}
            />
          )}
        />
      </div>

      <div className="flex w-5">
        <div className="w-6 m-0 px-3">
          <h6>{contactLocation === "international" ? "Province" : "State"}</h6>
          <Controller
            name="contactState"
            rules={{ required: "Contact State Required." }}
            control={formControl}
            defaultValue={
              sameAsClient ? fieldData.clientState : contactLocation
            }
            render={({ field }) => {
              if (contactLocation !== "international") {
                return (
                  <Dropdown
                    className="w-full"
                    id={field.name}
                    inputId={field.name}
                    placeholder="Select State"
                    value={sameAsClient ? fieldData.clientState : field.value}
                    options={states}
                    optionLabel="name"
                    onChange={(e) => {
                      field.onChange(e.value);
                    }}
                    filter
                    showClear
                  />
                );
              } else {
                return (
                  <InputText
                    className="w-full"
                    id={field.name}
                    placeholder="Enter Province"
                    value={sameAsClient ? fieldData.clientState : field.value}
                    // suggestions={cityList}
                    onChange={(e) => field.onChange(e.target.value)}
                    pt={{ root: { className: "w-full" } }}
                  />
                );
              }
            }}
          />
        </div>

        {contactLocation === "domestic" ? (
          <div className="w-4 px-3">
            <h6>Zip Code</h6>
            <Controller
              name="contactPostalCode"
              rules={{ required: "Contact Postal Code Required." }}
              control={formControl}
              defaultValue={
                sameAsClient ? fieldData.clientPostalCode : undefined
              }
              render={({ field }) => (
                <InputMask
                  id={field.name}
                  className="w-full"
                  placeholder="Enter Zip"
                  mask="99999"
                  value={
                    sameAsClient ? fieldData.clientPostalCode : field.value
                  }
                  onChange={(e) => field.onChange(e.value)}
                  // min={10000} max={99999}
                />
              )}
            />
          </div>
        ) : (
          <div className="w-4 px-3">
            <h6>Zip Code</h6>
            <Controller
              name="contactPostalCode"
              rules={{ required: "Contact postal code required." }}
              control={formControl}
              render={({ field }) => (
                <InputText
                  className="w-full"
                  id={field.name}
                  placeholder="Enter Zip"
                  value={
                    sameAsClient ? fieldData.clientPostalCode : field.value
                  }
                  // suggestions={cityList}
                  onChange={(e) => field.onChange(e.target.value)}
                  pt={{ root: { className: "w-full" } }}
                />
              )}
            />
          </div>
        )}
      </div>

      {contactLocation === "international" && (
        <div id="country-selection" className="w-3 m-0 px-3">
          <h6>Country</h6>
          <Controller
            name="contactCountry"
            rules={{ required: "Contact country required." }}
            control={formControl}
            render={({ field }) => {
              return (
                <Dropdown
                  className="w-full"
                  id={field.name}
                  inputId={field.name}
                  placeholder="Select Country"
                  value={
                    sameAsClient
                      ? SetCountry("contact", "client", getValues, setValue)
                      : field.value
                  }
                  options={countries}
                  optionLabel="name"
                  onChange={(e) => {
                    field.onChange(e.value);
                  }}
                  filter
                  showClear
                />
              );
            }}
          />
        </div>
      )}

      <div
        id="origination-selection"
        className="w-3 px-1 align-self-end flex flex-wrap justify-content-center"
      >
        <h6 className="w-full text-center">Client and Contact Address Same?</h6>
        <Controller
          name="contactSameAsClient"
          defaultValue={false}
          control={formControl}
          render={({ field }) => (
            <InputSwitch
              className="justify-content-center"
              id={field.name}
              checked={field.value}
              onChange={(e) => {
                field.onChange(e.value);
                setSameAsClient(e.value);
                // setAddressValues("client", "contact", setValue, getValues);
              }}
              // pt={{button: {className:'w-8'}}}
            />
          )}
        />
      </div>
      <div
        id="contact-search"
        className="w-3 px-1 align-self-end flex flex-wrap justify-content-center"
      >
        {/* <h6 className='w-full text-center'>Client and Contact Address Same?</h6> */}
        <Button
          className="justify-content-center"
          type="button"
          label="Search For Existing Contact"
          onClick={() => setSearchContactVis(true)}
        />
      </div>
      <div className="w-full flex justify-content-end">
        <Button
          className="mr-4"
          type="button"
          label="Back"
          onClick={() => onExpand(0)}
        />
        <Button label="Next" type="button" onClick={() => onExpand(2)} />
      </div>
      <Dialog
        header="Search For Existing Contact"
        visible={searchContactVis}
        onHide={() => setSearchContactVis(false)}
        footer={
          <Button onClick={() => setSearchContactVis(false)}>Close</Button>
        }
      >
        <div>
          <ExistingClientsTable
            existingContacts={existingContacts}
            fieldData={fieldData}
            formSetValue={setValue}
          />
        </div>
      </Dialog>
    </Panel>
  );
}
