import { useMsal } from "@azure/msal-react";
import { db } from "../../utils/indexeddb/db";

const signoutClickHandler = (instance) => {
    const logoutRequest =  {
        account: instance.getAllAccounts(),
        postLogoutRedirectUri: process.env.REACT_APP_REDIRECT_URI || "https://bmss.magnify-app.com",
    }
    localStorage.clear()
    sessionStorage.clear()
    db.delete()
    instance.logout(logoutRequest);
}

const buttonStyle = {
    border: "none",
    backgroundColor: "transparent",
    color: "var(--surface-200)",
    fontFamily: "var(--primary-header-font)",
    fontSize: "calc(0.25vw + .9rem)",
    cursor: "pointer",
    transition: "color 0.3s ease",
    marginLeft: "1rem",
}

export const SignOutButton = () => {
    const { instance } = useMsal();
    return (
        <button style={buttonStyle} onClick={() => signoutClickHandler(instance)}>Sign Out</button>
    )
}
