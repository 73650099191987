import { useSelector } from "react-redux";
import { useRef } from "react";
import { Button } from "primereact/button";
import { db } from "../../../../utils/indexeddb/db";
import { Toast } from "primereact/toast";
import { ProgressBar } from "primereact/progressbar";
import { useNavigate } from "react-router-dom";

export function ReviewFooter({ onHide, client, submitRef, callingPage, isValidForm }) {
    const navigate = useNavigate();

    const saveData = async (clientObject) => {
        console.log(clientObject);
        try {
            const addedClient = db.NEW_CLIENTS.put(clientObject);
            addedClient.then((data) =>
                console.log("Successfully Saved Client", data)
            );
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div className="mt-1">
            {callingPage !== "ncto" ? (
                // <Link to="/apps/ncto/form" state={{ clientData: client }}>
                <Button
                    aria-label="Edit"
                    tooltip="Edit"
                    type="button"
                    disabled={client.status === "IN PROGRESS" ? false : true}
                    onClick={() => {
                        if (client.status === "IN PROGRESS") {
                            navigate("/apps/ncto/form", { state: { clientData: client } });
                        }
                    }}
                >
                    Edit
                </Button>
            ) : (
                <Button aria-label="Edit" tooltip="Edit" type="button" onClick={onHide}>
                    Edit
                </Button>
            )}
            {callingPage === "ncto" && (
                <Button
                    disabled={!isValidForm}
                    type="submit"
                    onClick={() => {
                        submitRef.current.requestSubmit();
                        saveData(client);
                    }}
                >
                    Submit
                </Button>
            )}
        </div>
    );
}
