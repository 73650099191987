import { createSlice } from '@reduxjs/toolkit'
import { v4 as uuidv4 } from 'uuid';

export const globalsSlice = createSlice({
  name: 'globals',
  initialState: {
      apiRoot: "https://bmss-api.azurewebsites.net",
      sessionCounter: 0,
      sessionUUID: uuidv4()
  },
  reducers: {
    incrementSessionCounter: (state) => {
      state.sessionCounter += 1
    }
  }
})

export const { incrementSessionCounter } = globalsSlice.actions

export default globalsSlice.reducer